import snakeCase from "lodash/snakeCase";
import { TokenData } from "../../../auth/types";
import { request } from "../../../network/mainClient/request";
import { fromBase64 } from "../../../utils/base64";
import {
  ICountriesRegex,
  TVerificationApp,
  TVerificationDocumentDataItem,
  TVerificationDocumentFull,
  TVerificationRequest,
  VerificationAppDocumentType,
} from "../models";

export const getOrCreateVerificationRequest = async (
  category: string,
  appPublicKey: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/get-or-create-new-request/${category}`,
    method: "POST",
    body: {
      app_public_key: appPublicKey,
    },
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const saveVerificationSession = async (
  token: string,
  requestId: string
): Promise<void> => {
  const response = await request({
    path: "/api/v1/verification-service/token/session/save-session",
    method: "POST",
    body: {
      token,
      verification_request_id: requestId,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const restoreVerificationSession = async (
  token: string
): Promise<{ token: TokenData | null; verificationRequestId?: string }> => {
  const response = await request({
    path: "/api/v1/verification-service/token/restore-session",
    method: "POST",
    body: {
      token,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const getVerificationRequest = async (
  id: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const submitVerificationRequest = async (
  id: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/submit`,
    method: "POST",
    body: {
      transfer_data: true,
    },
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const sendVerificationRequest = async (
  id: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/send-to-verification`,
    method: "POST",
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const sendUIDataRequest = async (
  id: string,
  data: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/set-ui-data`,
    method: "POST",
    body: {
      verification_request_id: id,
      ui_data: data,
    },
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const getVerificationRequestQRCode = async (
  id: string
): Promise<{ data: string }> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${id}/qr-code`,
    method: "GET",
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

const fixDocumentsMap: { [key: string]: VerificationAppDocumentType } = {
  DriverLicenceFile: VerificationAppDocumentType.DriverLicence,
  IdFile: VerificationAppDocumentType.Id,
  PassportFileFile: VerificationAppDocumentType.Passport,
};

export const getVerificationApp = async (
  appPublicKey: string
): Promise<TVerificationApp> => {
  const response = await request({
    path: `/api/v1/verification-service/app/public-key/${appPublicKey}`,
    method: "GET",
  });

  if (response.ok && response.data.app) {
    const result = response.data.app as TVerificationApp;
    if (result.settings?.onboardingConfig) {
      result.settings.onboardingConfigParsed = JSON.parse(
        result.settings.onboardingConfig
      );
      result.settings.onboardingConfigParsed?.elements?.forEach((element) => {
        if (element.config?.elements || element.config?.fields) {
          element.allowed_documents = [element.type];
        } else {
          element.allowed_documents = [];
        }

        if (element.allowed_documents) {
          element.allowed_documents = element.allowed_documents.map(
            (docType) => {
              if (docType in fixDocumentsMap) {
                return fixDocumentsMap[docType];
              }
              return docType;
            }
          );
        }
      });
      if (result.settings.onboardingConfigParsed?.elements) {
        result.settings.onboardingConfigParsed.elements =
          result.settings.onboardingConfigParsed?.elements.filter(
            (x) =>
              !["ConfirmationOfAccuracy", "Selfie"].includes(x.type as string)
          );
      }
    }
    return result;
  }

  throw new Error(response.errorMessage || "No data");
};

export const fixFilesMap: { [key: string]: string } = {
  DriverLicenceFile_FirstPage: "DRIVER_LICENCE_FIRST_PAGE",
  DriverLicenceFile_SecondPage: "DRIVER_LICENCE_SECOND_PAGE",
  PassportFile_FirstPage: "PASSPORT_FIRST_PAGE",
  PassportFile_SecondPage: "PASSPORT_SECOND_PAGE",
  IdFile_FirstPage: "ID_FIRST_PAGE",
  IdFile_SecondPage: "ID_SECOND_PAGE",
  Residence_FirstPage: "RESIDENCE_FIRST_PAGE",
  Residence_SecondPage: "RESIDENCE_SECOND_PAGE",
  FinancialInformationFile_Page1: "FINANCIAL_INFORMATION_PAGE_1",
  DriverLicenceFile__FirstPage: "DRIVER_LICENCE_FIRST_PAGE",
  DriverLicenceFile__SecondPage: "DRIVER_LICENCE_SECOND_PAGE",
  PassportFile__FirstPage: "PASSPORT_FIRST_PAGE",
  PassportFile__SecondPage: "PASSPORT_SECOND_PAGE",
  IdFile__FirstPage: "ID_FIRST_PAGE",
  IdFile__SecondPage: "ID_SECOND_PAGE",
  Residence__FirstPage: "RESIDENCE_FIRST_PAGE",
  Residence__SecondPage: "RESIDENCE_SECOND_PAGE",
  FinancialInformationFile__Page1: "FINANCIAL_INFORMATION_PAGE_1",
  Selfie: "SELFIE",
};

export const getDocumentData = async (
  documentId: string
): Promise<TVerificationDocumentFull> => {
  const response = await request({
    path: `/api/v1/verification-service/document/${documentId}`,
    method: "GET",
  });

  if (response.ok) {
    if (response.data.data) {
      response.data.data.forEach((x: TVerificationDocumentDataItem) => {
        if (response.data.document?.documentType === "Selfie") {
          x.dataId = "SELFIE_FILE";
        } else if (x.dataId in fixFilesMap) {
          x.dataId = fixFilesMap[x.dataId];
        }
        if (x.dataBase64) {
          try {
            x.dataParsed = JSON.parse(fromBase64(x.dataBase64));
          } catch (e) {
            console.error(e);
          }
        }
      });
    }
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const uploadDocumentData = async (
  verificationRequestId: string,
  documentId: string,
  documentType: string,
  data: any
) => {
  const response = await request({
    path: `/api/v1/verification-service/document/${documentId}`,
    method: "POST",
    body: {
      [snakeCase(documentType)]: data || {},
    },
  });

  if (response.ok) {
    return response.data.document;
  }

  throw new Error(response.errorMessage);
};

export const setDocumentsToRequest = async (
  verificationRequestId: string,
  documents: string[]
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request/${verificationRequestId}`,
    method: "POST",
    body: {
      documents: documents,
    },
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const uploadDocumentFile = async (
  verificationRequestId: string,
  documentId: string,
  fileType: string,
  file: File
) => {
  const body = new FormData();
  body.append("attachment", file, file.name);

  let headers = {};
  if (file.name.split(".").pop() === "pdf")
    headers = { "Content-Type": "application/pdf" };

  const response = await request({
    path: `/api/v1/verification-service/document/${documentId}/upload-file/${fileType}`,
    method: "POST",
    headers: headers,
    body,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const deleteDocumentFile = async (
  verificationRequestId: string,
  documentId: string,
  fileType: string
) => {
  const response = await request({
    path: `/api/v1/verification-service/document/${documentId}/upload-file/${fileType}`,
    method: "DELETE",
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const getDocumentFingerPrintsById = async (
  requestId: string,
  documentId: string
) => {
  const response = await request({
    method: "GET",
    path: `/api/v1/verification-service/document/${documentId}/fingerprints`,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const getUserData = async () => {
  const response = await request({
    method: "GET",
    path: `/api/v1/user-service/me`,
  });

  if (response.ok) {
    return response.data?.user;
  }

  throw new Error(response.errorMessage);
};

export const getVerificationsList = async (): Promise<
  TVerificationRequest[]
> => {
  const response = await request({
    path: `/api/v1/verification-service/verification-request?limit=100`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export type TCompany = {
  id: string;
  name: string;
  isActive: boolean;
  purpose: string;
  picture: string;
};

export const getCompany = async (
  company_id: string | number
): Promise<TCompany> => {
  const response = await request({
    path: `/api/v1/company-service/${company_id}`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.company;
  }

  throw new Error(response.errorMessage);
};

export const syncVerificationRequest = async (
  verification_request_id: string,
  verificationId: string
): Promise<TVerificationRequest> => {
  const response = await request({
    path: `/api/v1/verification-service/${verification_request_id}/share-documents`,
    method: "POST",
    body: { verificationId },
  });

  if (response.ok) {
    return response.data.verificationRequest;
  }

  throw new Error(response.errorMessage);
};

export const getCountryRegexList = async (): Promise<ICountriesRegex> => {
  const response = await request({
    path: "/api/v1/user-service/countryregex",
    method: "GET",
  });

  if (response.ok) {
    const countriesRegex: ICountriesRegex = {};
    for (let regexItem of response.data.countryRegex) {
      countriesRegex[regexItem.country] = regexItem;
    }
    return countriesRegex;
  }

  throw new Error(response.errorMessage);
};
