import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type Props = {
  onResend: () => void;
};

const RESEND_DELAY = 60; // seconds

export const ResendSms = (props: Props) => {
  const { onResend } = props;
  const [timeLeft, setTimeLeft] = useState(RESEND_DELAY);
  const [timerIsActive, setTimerIsActive] = useState(true);

  useEffect(() => {
    if (!timerIsActive) {
      return;
    }
    const timeout = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
      if (timeLeft <= 1) {
        setTimerIsActive(false);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [timeLeft, setTimeLeft, timerIsActive, setTimerIsActive]);

  const resend = useCallback(async () => {
    await onResend();
    setTimeLeft(RESEND_DELAY);
    setTimerIsActive(true);
  }, [onResend, setTimeLeft, setTimerIsActive]);

  return (
    <Button
      type='button'
      disabled={timeLeft > 0}
      onClick={resend}
      fullWidth
      sx={{
        marginBottom: "32px",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.5px",
        color: "#8D959F",
        textTransform: "capitalize",
      }}
    >
      {timeLeft > 0 ? `Resend code in ${timeLeft} sec` : "Resend code"}
    </Button>
  );
};
