import { getMaxDate } from "../../utils/getMaxDate";
import { FC, useEffect, useState } from "react";
import { TVerificationAppFieldConfigComponent } from "../../models";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "react-final-form";
import { DatePicker } from "@mui/x-date-pickers";

export const DatePickerCustom: FC<TVerificationAppFieldConfigComponent> = ({
  name,
  label,
  required,
}) => {
  const [date, setDate] = useState<Dayjs | null>(null);
  const form = useForm();

  const handleChangeDate = (value: any) => {
    setDate(value);
    form.change(`data.${name}`, value.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (form.getState().values?.data?.[name])
      setDate(dayjs(form.getState().values?.data?.date_of_birth, "YYYY-MM-DD"));
  }, [form, name]);

  return (
    <DatePicker
      maxDate={getMaxDate()}
      defaultValue={getMaxDate()}
      value={date}
      onChange={(value: any) => handleChangeDate(value)}
      label={label}
      format='DD-MM-YYYY'
      slotProps={{
        textField: {
          required: required,
        },
      }}
    />
  );
};
