import { Box, Stack, Typography } from "@mui/material";
import { Select, TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { required, requiredCustom } from "../../../../../utils/validation";
import { FileInput } from "../../../../ui/components/FileInput";
import { CardId } from "../../../../../assets/icons";
import {
  ChangeEvent,
  FormEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-final-form";
import { useOnBoardingFormContext } from "../../../hooks/useOnBoardingFormContext";
import { useMultiInputs } from "../../../hooks/useMultiInputs";
import { CustomMultiSelect } from "../../../../ui/components/CustomMultiSelect";
import { countrySelectData } from "../../../data/countriesData";
import { useCountryRegex } from "../../../hooks/useCountryRegEx";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IsJsonString } from "../../../../../utils/isJsonString";
export const employmentIncome = "Employment income (salary/bonus)";
export const perYear = "EUR per year";

const source_of_wealth = [
  {
    name: `${employmentIncome} ${perYear}`,
  },
  {
    value: `${employmentIncome} 0 – 50’000 ${perYear}`,
    label: "0 – 50’000 ",
    parent: `${employmentIncome} ${perYear}`,
  },
  {
    value: `${employmentIncome} 50’000 - 100’000 ${perYear}`,
    label: "50’000 - 100’000",
    parent: `${employmentIncome} ${perYear}`,
  },
  {
    value: `${employmentIncome} 100’000 - 200’000 ${perYear}`,
    label: "100’000 - 200’000",
    parent: `${employmentIncome} ${perYear}`,
  },
  {
    value: `${employmentIncome} 200’000 - 500’000 ${perYear}`,
    label: "200’000 - 500’000",
    parent: `${employmentIncome} ${perYear}`,
  },
  {
    value: `${employmentIncome} 500’000 – 1’000’000 ${perYear}`,
    label: "500’000 – 1’000’000",
    parent: `${employmentIncome} ${perYear}`,
  },
  {
    value: `${employmentIncome} over 1’000’000 ${perYear}`,
    label: "Over 1’000’000",
    parent: `${employmentIncome} ${perYear}`,
  },
  {
    value: "Maturity life insurance policy",
    label: "Maturity life insurance policy",
  },
  {
    value: "Sale of investments/liquidation investment portfolio",
    label: "Sale of investments/liquidation investment portfolio",
  },
  {
    value: "Sale of property/assets",
    label: "Sale of property/assets",
  },
  {
    value: "Inheritance",
    label: "Inheritance",
  },
  {
    value: "Business income (profits)",
    label: "Business income (profits)",
  },
  {
    value: "Gift",
    label: "Gift",
  },
  {
    value: "Virtual asset mining",
    label: "Virtual asset mining",
  },
  {
    value: "Virtual asset trading",
    label: "Virtual asset trading",
  },
  // {
  // name: 'Virtual asset trading',
  // },
  // {
  // value: 'Other exchanges used',
  // label: 'Other exchanges used',
  // parent: 'Virtual asset trading',
  // },
  // {
  // value: 'Years of experience trading',
  // label: 'Years of experience trading',
  // parent: 'Virtual asset trading',
  // },
  // {
  // value: 'Addresses used at other trading sites',
  // label: 'Addresses used at other trading sites',
  // parent: 'Virtual asset trading',
  // },
  {
    value: "Other",
    label: "Other (please specify)",
  },
];

const expected_volume_of_transactions = [
  {
    value: "Up to 1,000",
    label: "Up to 1,000",
  },
  {
    value: "1,000 - 10,000",
    label: "1,000 - 10,000",
  },
  {
    value: "10,000 - 25,000",
    label: "10,000 - 25,000",
  },
  {
    value: "25,000 - 50,000",
    label: "25,000 - 50,000",
  },
  {
    value: "50,000 - 100,000",
    label: "50,000 - 100,000",
  },
  {
    value: "100,000 - 250,000",
    label: "100,000 - 250,000",
  },
  {
    value: "250,000 - 500,000",
    label: "250,000 - 500,000",
  },
  {
    value: "500,000 - 1 Mln.",
    label: "500,000 - 1 Mln.",
  },
  {
    value: "1 Mln. - 5 Mln.",
    label: "1 Mln. - 5 Mln.",
  },
  {
    value: "5 Mln. - 10 Mln.",
    label: "5 Mln. - 10 Mln.",
  },
  {
    value: "Over 10 Mln.",
    label: "Over 10 Mln.",
  },
];

const source_of_funds = [...source_of_wealth];

const estimated_total_wealth = [...expected_volume_of_transactions];
const estimated_values = estimated_total_wealth.map((obj) => obj.value);

export const FinancialInformation = (props: {
  excludedFields: string[] | undefined;
}) => {
  const { excludedFields } = props;
  const [isDocumentsRequired, setIsDocumentsRequired] = useState(false);
  const [countriesRegex] = useCountryRegex();
  const [sourceFunds, setSourceFunds] = useState<string[]>([]);
  const [expainSourceFunds, setExpainSourceFunds] = useState<string>("");
  const [sourceWealth, setSourceWealth] = useState<string[]>([]);
  const [expainSourceWealth, setExpainSourceWealth] = useState<string>("");
  const [isDescriptionReq, setIsDescriptionReq] = useState(false);
  const [regexCountries, setRegexCountries] = useState<string[]>([]);
  const [taxIds, setTaxIds] = useState<{ [key: string]: string }[]>([]);
  const {
    files: { FinancialInformation },
    uploadedFiles,
    formData,
  } = useOnBoardingFormContext();

  const finInfoValues = Object.values(formData).find(
    (el: any) => el.documentType === "FinancialInformation"
  );

  const { filesCount, isMaximumInputs, handleAddInput, handleDeleteInput } =
    useMultiInputs(Object.keys(finInfoValues?.files || {}).length, 10);

  const {
    filesCount: tinCount,
    isMaximumInputs: isMaximumTins,
    handleAddInput: handleAddTin,
    handleDeleteInput: handleDeleteTin,
  } = useMultiInputs(
    !IsJsonString(finInfoValues?.data?.tax_id)
      ? 1
      : JSON.parse(finInfoValues?.data?.tax_id ?? "{}")?.length,
    3
  );
  const form = useForm();

  const { t } = useTranslation("onBoarding", {
    keyPrefix: "FinancialInformation.fields",
  });

  const checkIsDocumentsRequired = (volume: string, wealth: string) => {
    return [
      "50,000 - 100,000",
      "100,000 - 250,000",
      "250,000 - 500,000",
      "500,000 - 1 Mln.",
      "1 Mln. - 5 Mln.",
      "5 Mln. - 10 Mln.",
      "Over 10 Mln.",
    ].includes(volume);
  };

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement;

    setIsDescriptionReq(
      [
        "25,000 - 50,000",
        "50,000 - 100,000",
        "100,000 - 250,000",
        "250,000 - 500,000",
        "500,000 - 1 Mln.",
        "1 Mln. - 5 Mln.",
        "5 Mln. - 10 Mln.",
        "Over 10 Mln.",
      ].includes(target?.value)
    );
    setIsDocumentsRequired(
      checkIsDocumentsRequired(
        target?.value,
        form.getState().values.data.estimated_total_wealth
      )
    );
  };

  const handleEstimatedChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement;
    // setIsDocumentsRequired(
    //   checkIsDocumentsRequired(
    //     form.getState().values.data.expected_volume_of_transactions,
    //     target?.value
    //   )
    // );
  };

  const handleDetailedDescChange = () => {
    if (!isDescriptionReq) {
      const plannedInvest =
        form.getState().values.data.expected_volume_of_transactions;
      setIsDescriptionReq(
        [
          "25,000 - 50,000",
          "50,000 - 100,000",
          "100,000 - 250,000",
          "250,000 - 500,000",
          "500,000 - 1 Mln.",
          "Over 1 Mln.",
        ].includes(plannedInvest)
      );
    }
  };

  const handleSourceExplainChange = (
    event: ChangeEvent<HTMLInputElement>,
    formKey: "source_of_funds" | "source_of_wealth"
  ) => {
    const target = event.target as HTMLButtonElement;
    const newExplain = target?.value;

    const setExplainFunc =
      formKey === "source_of_funds"
        ? setExpainSourceFunds
        : setExpainSourceWealth;
    const source = formKey === "source_of_funds" ? sourceFunds : sourceWealth;

    setExplainFunc(newExplain);

    const indexOfOther = source.indexOf("Other");
    let sourceCopy = JSON.parse(JSON.stringify(source));
    if (indexOfOther > -1) {
      sourceCopy = sourceCopy.filter((elem: string) => elem !== "Other");
      sourceCopy.push("Other");
    }
    let newVal =
      sourceCopy
        ?.map((el: string) => (el === "Other" ? "Other: " : el))
        .join(", ") + newExplain;

    form.change("data." + formKey, newVal);
  };

  const handleSourceChange = (
    event: ChangeEvent<HTMLInputElement>,
    cb: (value: SetStateAction<string[]>) => void
  ) => {
    const target = event.target as HTMLButtonElement;
    let values = event.target.value as unknown as string[];
    const newValue = values[values.length - 1];
    if (newValue.includes("Employment income")) {
      cb((prev) => {
        if (prev.length < values.length) {
          values = [
            ...prev.filter(
              (prevItem) => !prevItem.includes("Employment income")
            ),
            newValue,
          ];
        }
        return values;
      });
    } else {
      cb(values);
    }

    const indexOfOther = values.indexOf("Other");
    if (indexOfOther > -1) {
      values = values.filter((elem) => elem !== "Other");
      values.push("Other");
    }
    const explain =
      target?.name === "data.source_of_funds"
        ? expainSourceFunds
        : expainSourceWealth;
    const newVal = values
      ?.map((el: string) => (el === "Other" ? "Other: " + explain : el))
      .join(", ");

    form.change(target?.name, newVal);
  };

  useEffect(() => {
    const currency = form.getFieldState("data.currency")?.value;
    if (!currency) form.change("data.currency", "EUR");
  }, [form]);

  useEffect(() => {
    const variantsModel = [
      "expected_volume_of_transactions",
      "estimated_total_wealth",
    ];
    variantsModel.forEach((variant) => {
      const currentVal = form.getState().values.data?.[variant] || "";
      if (currentVal && !estimated_values.includes(currentVal)) {
        form.change("data." + variant, "");
      }
    });
  }, [form]);

  useEffect(() => {
    const variantsModel = [
      {
        data: source_of_funds,
        key: "source_of_funds",
        cb: setSourceFunds,
        setExplain: setExpainSourceFunds,
      },
      {
        data: source_of_wealth,
        key: "source_of_wealth",
        cb: setSourceWealth,
        setExplain: setExpainSourceWealth,
      },
    ];
    variantsModel.forEach((variant) => {
      const currentVal = form.getState().values.data?.[variant?.key] || "";
      let subStr = currentVal;
      const otherIndex = currentVal?.indexOf("Other");
      if (otherIndex > -1) {
        subStr = subStr.substring(0, otherIndex + 5);
        const explain = currentVal.substring(otherIndex + 6).trim();
        variant.setExplain(explain);
        form.change("data.del_" + variant.key, explain);
      }
      const array =
        subStr.length > 0
          ? subStr.split(", ").filter((el: string) => el !== "")
          : [];
      variant.cb(array);
    });
  }, [form]);

  const handleTinChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    options?: {
      isRemove?: boolean;
      newCountry?: string;
    }
  ) => {
    const target = event.target as HTMLButtonElement;
    const removeItem = (array: any[], index: number) =>
      array.filter((item, itemIndex) => itemIndex !== index);
    setTaxIds((prev) => {
      let newArr = [...prev];
      if (options?.isRemove) {
        newArr = removeItem(newArr, index);
      } else if (options?.newCountry) {
        const currentTaxValue = newArr[index]?.[regexCountries[index]];
        newArr[index] = { [options.newCountry]: currentTaxValue };
      } else {
        newArr[index] = { [regexCountries[index]]: target.value.trim() };
      }
      return newArr;
    });
  };

  const handleRegexCountryChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    isRemove?: boolean
  ) => {
    const target = event.target as HTMLButtonElement;
    setRegexCountries((prev) => {
      let newArr = !isRemove
        ? [...prev]
        : [...prev].filter((item, itemIndex) => {
            return itemIndex !== index;
          });
      if (!isRemove) {
        if (newArr[index]) {
          newArr[index] = target.value;
          const mokEvent: any = { target: { value: "" } };
          handleTinChange(mokEvent, index, { newCountry: target.value });
        } else {
          newArr[index] = target.value;
        }
      }
      return newArr;
    });
  };

  useEffect(() => {
    const getResidenceCountry = () => {
      const residence = Object.values(formData).find(
        (el: any) => el.documentType === "Residence"
      );
      return {
        event: { target: { value: residence?.data?.country } } as any,
        country: residence?.data?.country,
      };
    };
    try {
      const tax_id = form.getFieldState("data.tax_id")?.value;
      form.change("tin", "animzuk");
      if (tax_id) {
        const taxIds = JSON.parse(tax_id);
        if (Array.isArray(taxIds)) {
          const countries: string[] = [];
          for (let countryObj of taxIds) {
            const country = Object.keys(countryObj)[0];
            countries.push(country);
          }
          setRegexCountries(countries);
          setTaxIds(taxIds);
        } else {
          const { event, country } = getResidenceCountry();
          handleRegexCountryChange(event, 0);
          setTaxIds([{ [country]: tax_id }]);
        }
      } else {
        const { event } = getResidenceCountry();
        handleRegexCountryChange(event, 0);
      }
    } catch (e) {}
  }, [form, formData]);

  useEffect(() => {
    form.change("data.tax_id", JSON.stringify(taxIds));
  }, [form, taxIds]);

  return (
    <Stack spacing={2} width='100%' marginBottom={6}>
      {!excludedFields?.includes("expected_volume_of_transactions") ? (
        <Select
          name='data.expected_volume_of_transactions'
          label={t("expected_volume_of_transactions")}
          data={expected_volume_of_transactions}
          required
          fieldProps={{ validate: required }}
          inputProps={{ onChange: handleVolumeChange }}
        />
      ) : null}
      <Box>
        <CustomMultiSelect
          name='data.source_of_funds'
          label={t("source_of_funds")}
          data={source_of_funds}
          required
          validate={required}
          onChange={handleSourceChange}
          values={sourceFunds}
          setValue={setSourceFunds}
        />
        <TextField
          name='data.source_of_funds'
          label={t("source_of_funds")}
          sx={{ display: "none" }}
        />
        <TextField
          name='data.del_source_of_funds'
          label={t("source_explain")}
          required={sourceFunds.includes("Other")}
          sx={{
            marginTop: "16px",
            display: sourceFunds.includes("Other") ? "block" : "none",
          }}
          defaultValue={expainSourceFunds}
          inputProps={{
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              handleSourceExplainChange(e, "source_of_funds"),
          }}
        />
        <Typography variant='caption' paddingLeft='12px'>
          {t("field_additions.source_of_funds")}
        </Typography>
      </Box>

      <Select
        name='data.estimated_total_wealth'
        label={t("estimated_total_wealth")}
        data={estimated_total_wealth}
        required
        fieldProps={{ validate: required }}
        inputProps={{ onChange: handleEstimatedChange }}
      />
      <Box>
        <CustomMultiSelect
          MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
          data={source_of_wealth}
          name='data.source_of_wealth'
          label={t("source_of_wealth")}
          required
          validate={required}
          onChange={handleSourceChange}
          values={sourceWealth}
          setValue={setSourceWealth}
        />
        <TextField
          name='data.source_of_wealth'
          label={t("source_of_wealth")}
          sx={{ display: "none" }}
        />
        <TextField
          name='data.del_source_of_wealth'
          label={t("source_explain")}
          required={sourceWealth.includes("Other")}
          sx={{
            marginTop: "16px",
            display: sourceWealth.includes("Other") ? "block" : "none",
          }}
          inputProps={{
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              handleSourceExplainChange(e, "source_of_wealth"),
          }}
        />
        <Typography variant='caption' paddingLeft='12px'>
          {t("field_additions.source_of_wealth")}
        </Typography>
      </Box>

      <TextField
        name='data.description_source_of_funds'
        label={t("description_source_of_funds")}
        multiline
        required={isDescriptionReq}
        inputProps={{ onChange: handleDetailedDescChange }}
      />
      {tinCount.map((count) => {
        const regexCountry = regexCountries[count - 1];
        const regexConfig = countriesRegex[regexCountry];
        const regex = regexConfig?.regexp ? new RegExp(regexConfig.regexp) : "";
        const check =
          regex && taxIds[count - 1]?.[regexCountry]
            ? regex.test(taxIds[count - 1][regexCountry])
            : "none";

        return (
          <Stack
            key={"tin" + count}
            position='relative'
            direction='row'
            spacing={2}
            width='100%'
          >
            <Stack flexBasis='25%'>
              <Select
                MenuProps={{
                  sx: { height: window.innerWidth < 400 ? 600 : "auto" },
                }}
                name='tin'
                label='Country'
                inputProps={{
                  onChange: (e) => handleRegexCountryChange(e, count - 1),
                }}
                renderValue={(value) => value as ReactNode}
                value={regexCountry ?? ""}
                data={countrySelectData}
                key={"countrySelect" + count}
              />
            </Stack>
            <Stack position='relative' flexBasis='75%'>
              <TextField
                sx={{
                  label: {
                    color:
                      check === "none"
                        ? null
                        : check
                        ? "green !important"
                        : "orange !important",
                  },
                }}
                inputProps={{ onChange: (e) => handleTinChange(e, count - 1) }}
                value={taxIds[count - 1]?.[regexCountry]}
                name={"data.tax_id"}
                label={t("tax_id") + (check ? "" : " (check the format)")}
              />
              <Typography
                variant='caption'
                component='p'
                paddingLeft='12px'
                marginTop='4px'
              >
                {regexConfig?.example || ""}
              </Typography>
            </Stack>
            {!isMaximumTins && tinCount.length === count ? (
              <AddIcon
                color='primary'
                onClick={handleAddTin}
                style={{
                  position: "absolute",
                  transform: "translateY(-50%)",
                  top: regexConfig?.example ? "35%" : "50%",
                  right: -35,
                  fontSize: 22,
                  border: "1px solid #1976d2",
                  borderRadius: "50%",
                }}
              />
            ) : null}
            {count !== 1 ? (
              <RemoveIcon
                color='primary'
                onClick={(e: any) =>
                  handleDeleteTin(e, count, {
                    cb: () => {
                      handleTinChange(e, count - 1, { isRemove: true });
                      handleRegexCountryChange(e, count - 1, true);
                    },
                    noKey: true,
                  })
                }
                style={{
                  position: "absolute",
                  transform: "translateY(-50%)",
                  top: regexConfig?.example ? "35%" : "50%",
                  right: !isMaximumTins ? -70 : -35,
                  fontSize: 22,
                  border: "1px solid #1976d2",
                  borderRadius: "50%",
                }}
              />
            ) : null}
          </Stack>
        );
      })}

      <Box>
        <TextField
          name='data.crypto_wallets'
          label={t("crypto_wallets")}
          multiline
        />
        <Typography
          variant='caption'
          component='p'
          paddingLeft='12px'
          marginTop='4px'
        >
          {t("field_additions.crypto_wallets")}
        </Typography>
      </Box>

      {filesCount.map((count) => {
        if (count === 1) {
          return (
            <FileInput
              handleAddInput={
                filesCount.length === count ? handleAddInput : undefined
              }
              isDeleteBan={uploadedFiles[`FINANCIAL_INFORMATION_DOCUMENT_1`]}
              document={
                FinancialInformation?.[`FinancialInformationFile__Page1`]
              }
              namePrefix={`files.FINANCIAL_INFORMATION_DOCUMENT_1`}
              label={t("documents")}
              subtitle={t("documents_subtitle")}
              icon={CardId}
              height={121}
              validate={
                isDocumentsRequired
                  ? (e) =>
                      requiredCustom(
                        FinancialInformation?.[
                          `FinancialInformationFile__Page1`
                        ]?.dataLink ?? e,
                        "Please provide documentary proof of your funds to the amount exceeding the planned investment on this platform"
                      )
                  : undefined
              }
              // для применения изменения validate
              key={isDocumentsRequired ? "required" : "notRequired"}
            />
          );
        } else {
          return (
            <FileInput
              handleAddInput={
                filesCount.length === count ? handleAddInput : undefined
              }
              handleDeleteInput={(e: Event, options) =>
                handleDeleteInput(e, count, options)
              }
              isDeleteBan={
                uploadedFiles[`FINANCIAL_INFORMATION_DOCUMENT_${count}`]
              }
              document={
                FinancialInformation?.[`FinancialInformationFile__Page${count}`]
              }
              isMaximumInputs={isMaximumInputs}
              namePrefix={`files.FINANCIAL_INFORMATION_DOCUMENT_${count}`}
              label={t("documents")}
              subtitle={t("documents_subtitle")}
              icon={CardId}
              height={121}
              key={count}
            />
          );
        }
      })}
    </Stack>
  );
};
