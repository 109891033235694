import { CardContent, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  TVerificationAppElementConfig,
  VerificationAppDocumentType,
} from "../../models";
import countries from "../../data/counties.json";

const countryData: { [key: string]: string } = Object.fromEntries(
  countries.map((x) => [x.code, x.name])
);

export type TProps = {
  lines: [string, any][];
  filledDocType?: VerificationAppDocumentType;
  config: TVerificationAppElementConfig | undefined;
};

export const ReviewStepItemFields = (props: TProps) => {
  const { lines, filledDocType, config } = props;
  const { t, i18n } = useTranslation(`onBoarding`, {
    keyPrefix: `${filledDocType}.fields`,
  });

  const keys =
    filledDocType &&
    Object.keys(
      //@ts-ignore
      i18n.options.resources?.[i18n.language]?.onBoarding?.[filledDocType]
        ?.fields
    );

  const checkIsCountry = (title: string, value: string) => {
    return (
      title.includes("country") ||
      title.includes("nationality") ||
      (title.includes("middle") && countryData[value])
    );
  };

  const parseTaxIds = (line: [string, string]) => {
    try {
      const taxIds = JSON.parse(line[1]);
      if (Array.isArray(taxIds)) {
        const names: string[] = [];
        const values: string[] = [];
        for (let countryObj of taxIds) {
          const country = Object.keys(countryObj)[0];
          const taxId = countryObj[country];
          // names.push(`${t(line[0])} (${countryData[country]})`);
          names.push(`${t(line[0])} (${country})`);
          values.push(taxId);
        }
        return [names, values];
      } else {
        return [line[0], line[1]];
      }
    } catch (e) {
      return [line[0], line[1]];
    }
  };

  return lines.length ? (
    <>
      <Divider
        sx={{
          margin: "0 16px",
        }}
      />
      <CardContent>
        <Stack spacing={1}>
          {lines.map((line, index) => {
            let name: string | string[] = line[0];
            let value = line[1];
            if (line[0] === "tax_id") {
              [name, value] = parseTaxIds(line);
            }

            return keys?.includes(line[0]) ? (
              <Stack key={index} direction='row' justifyContent='space-between'>
                {name === "expected_volume_of_transactions" ? (
                  <Typography fontWeight='700'>
                    Planned Investment <br /> per Year (EUR)
                  </Typography>
                ) : Array.isArray(name) ? (
                  <Typography fontWeight='700'>
                    {name.map((nameItem) => (
                      <p>{nameItem}</p>
                    ))}
                  </Typography>
                ) : (
                  <Typography fontWeight='700'>
                    {config?.fields?.find((field) => field.name === line[0])
                      ?.label ?? t(line[0])}
                  </Typography>
                )}
                <Typography
                  minWidth='40%'
                  maxWidth='60%'
                  textAlign='end'
                  sx={{ wordWrap: "break-word" }}
                >
                  {checkIsCountry(line[0], line[1])
                    ? countryData[line[1]]
                    : Array.isArray(value)
                    ? value.map((valueItem) => <p>{valueItem}</p>)
                    : line[1]}
                </Typography>
              </Stack>
            ) : null;
          })}
        </Stack>
      </CardContent>
    </>
  ) : null;
};
