import {
  Avatar,
  Badge,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TLoadingStateItem } from "../../hooks/useOnBoardingFormUpload";
import {
  AML_STORAGE,
  TVerificationAppElement,
  TVerificationRequest,
  VerificationAppDocumentType,
} from "../../models";
import { getDocumentIcon } from "../../utils/getDocumentIcon";
import CheckIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/system";
import { BanForm, SuccessForm } from "../../../../assets/icons";
import { CustomBadge } from "./styles";
import { TFormData } from "../../containers";
import { ReviewStepItemFields } from "../ReviewStepItemFields";
import { useMemo } from "react";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import { fixDateFormat } from "../../utils/fixDate";

function moveKeyAfter(
  initObj: any,
  keyToMove: string,
  keyAfter: string,
  email?: string
) {
  const obj = { ...initObj };
  if (email) {
    obj.email = email;
  }
  if (keyToMove === keyAfter) {
    return obj;
  }

  let keys = Object.keys(obj);
  let indexAfter = keys.indexOf(keyAfter);
  if (indexAfter === -1) {
    return obj;
  }

  let temp = { [keyToMove]: obj[keyToMove] };
  delete obj[keyToMove];
  keys.splice(keys.indexOf(keyToMove), 1);

  return {
    ...Object.fromEntries(
      keys.slice(0, indexAfter).map((key) => [key, obj[key]])
    ),
    ...temp,
    ...Object.fromEntries(keys.slice(indexAfter).map((key) => [key, obj[key]])),
  };
}

const transformDateOfData = (initObj: any) => {
  const newObj = { ...initObj };
  for (let field of ["date_of_birth", "issued_by", "issued_at", "expired_at"]) {
    newObj[field] = fixDateFormat(newObj[field]);
  }
  return newObj;
};

export type TProps = {
  stepConfig: TVerificationAppElement;
  stepIndex: string;
  filledDocType?: VerificationAppDocumentType;
  uploadState?: TLoadingStateItem;
  formData?: TFormData;
  verificationRequest?: TVerificationRequest | null;
  files: any;
  email?: string;
  isSharing?: boolean;
  isSharingAccepted?: boolean;
  isNeedToFiil?: boolean;
};

const StatusWrapper = (props: any) => {
  const { children } = props;
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};

export const ReviewStepItem = (props: TProps) => {
  const {
    stepConfig,
    stepIndex,
    filledDocType,
    uploadState,
    formData,
    files,
    email,
    isSharing = false,
    isSharingAccepted = false,
    isNeedToFiil = false,
  } = props;
  const { t } = useTranslation("onBoarding");
  const { setIsSharing, setIsSharingNeedFilling } = useOnBoardingFormContext();

  let action = (
    <Button
      disabled={uploadState?.loading || uploadState?.success}
      component={Link}
      onClick={() => {
        setIsSharing(false);
        setIsSharingNeedFilling(true);
      }}
      to={
        isNeedToFiil
          ? `../${stepIndex}/${stepConfig.type}/sharing`
          : `../${stepIndex}`
      }
      sx={{
        margin: "0",
        width: "66px",
        height: "32px",
        background: "#EEF3FE",
        borderRadius: "8px",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        color: "#2968EF",
        textTransform: "none",
      }}
    >
      Edit
    </Button>
  );

  if (uploadState?.loading) {
    action = (
      <StatusWrapper>
        <CircularProgress size={24} />
      </StatusWrapper>
    );
  } else if (uploadState?.success) {
    action = (
      <StatusWrapper>
        <CheckIcon color='success' />
      </StatusWrapper>
    );
  } else if (uploadState?.error) {
    action = (
      <Stack direction='row' spacing={1}>
        {action}
        <StatusWrapper>
          <Tooltip title={uploadState?.error?.message || uploadState?.error}>
            <ErrorIcon color='error' />
          </Tooltip>
        </StatusWrapper>
      </Stack>
    );
  }

  let contentFields =
    !["Selfie", "ProofOfResidence", "BeneficialOwnership"].includes(
      stepConfig.type
    ) &&
    Object.entries(
      stepConfig.type === "UserInformation" // TODO Сделано условие, так как на момент задачи не было возможности менять бэк для смены порядка
        ? moveKeyAfter(
            formData?.[stepIndex]?.data || {},
            "middle_name",
            "nationality",
            email
          )
        : stepConfig.type === "FinancialInformation"
        ? moveKeyAfter(
            formData?.[stepIndex]?.data || {},
            "tax_id",
            "description_source_of_funds"
          )
        : stepConfig.type === "AmlStep"
        ? transformDateOfData(formData?.[stepIndex]?.data)
        : formData?.[stepIndex]?.data || {}
    ).filter((line) => !!line[1]);

  let docIsLoaded = useMemo(() => {
    const isAmlVisited = JSON.parse(
      localStorage.getItem(AML_STORAGE) ?? "false"
    );
    if (stepConfig.type === "AmlStep") {
      return !isAmlVisited
        ? (files.Passport?.declineReason === null ||
            files.Id?.declineReason === null ||
            files.DriverLicence?.declineReason) &&
            files.Selfie?.declineReason === null
        : true;
    }
    return !!filledDocType;
  }, [filledDocType, stepConfig, files]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        background: "#FFFFFF",
        borderRadius: "14px",
      }}
    >
      <CardHeader
        avatar={
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            badgeContent={
              <CustomBadge src={docIsLoaded ? SuccessForm : BanForm} />
            }
          >
            <Avatar
              sx={{
                bgcolor: "#FFF",
                color: docIsLoaded ? "#1C1F21" : "#8D959F",
              }}
            >
              {getDocumentIcon(stepConfig.type)}
            </Avatar>
          </Badge>
        }
        action={
          !isSharing || (isNeedToFiil && stepConfig.type !== "AmlStep")
            ? action
            : null
        }
        title={
          t(`${stepConfig.type}.title`) +
          (stepConfig.type === "AmlStep" &&
          isSharing &&
          formData?.[stepIndex]?.documentType
            ? ` (${formData?.[stepIndex]?.documentType})`
            : "")
        }
        subheader={
          (filledDocType &&
            (stepConfig.allowed_documents?.length || 0) > 1 &&
            t(`${filledDocType}.title`)) ||
          (isSharing && !docIsLoaded && stepConfig.type !== "AmlStep" && (
            <span style={{ color: "red" }}>
              Please fill in the missing information
            </span>
          ))
        }
        sx={{
          "& .MuiCardHeader-action": {
            margin: "0",
            alignSelf: "center",
          },
        }}
      />
      {contentFields &&
        ((!isNeedToFiil && isSharingAccepted) || !isSharing) && (
          <ReviewStepItemFields
            lines={contentFields}
            filledDocType={filledDocType}
            key={filledDocType}
            config={stepConfig.config}
          />
        )}
    </Card>
  );
};
