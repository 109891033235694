import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Field, useField } from "react-final-form";
import { FFInputFile } from "../FFInputFile";
import { FileBlock } from "../FileBlock";
import { FilePreviewDialog } from "../FilePreviewDialog";

export type Tprops = {
  namePrefix: string;
  label?: string;
  subtitle?: string;
  InputComponent?: any;
  width?: string;
  height?: number;
  icon?: any;
  multiple?: boolean;
  validate?: (value?: string | undefined) => string | undefined;
  handleAddInput?: (e: Event) => void;
  handleDeleteInput?: (
    e: Event,
    options?: { cb?: () => void; noKey?: boolean }
  ) => void;
  key?: any;
  document?: any;
  required?: boolean;
  isDeleteBan?: boolean;
  isMaximumInputs?: boolean;
};

export enum FileType {
  "application/pdf" = "PDF",
  "image" = "Image",
}

export const FileInput: FC<Tprops> = ({
  namePrefix,
  label,
  subtitle,
  width,
  height,
  icon,
  multiple,
  validate,
  handleAddInput,
  handleDeleteInput,
  key,
  document,
  required,
  isDeleteBan,
  isMaximumInputs,
}) => {
  const { input } = useField(namePrefix);
  const [open, setOpen] = useState<boolean>(false);
  const [currentFileType, setCurrentFileType] = useState<FileType>();

  const previewImg = useMemo(() => {
    return (input?.value && URL.createObjectURL(input.value)) || null;
  }, [input?.value]);
  const dataLink = useMemo(() => document?.dataLink, [document?.dataLink]);

  useEffect(() => {
    const getContentType = async () => {
      if (previewImg || dataLink) {
        try {
          const response = await fetch(previewImg || dataLink);
          const contentType = response.headers.get("content-type");
          if (contentType === "application/pdf") {
            setCurrentFileType(FileType["application/pdf"]);
            return;
          }
          setCurrentFileType(FileType.image);
        } catch (e) {
          console.log("e", e);
        }
      }
    };

    getContentType();
  }, [previewImg, dataLink]);

  const handleDelete = useCallback(() => {
    input.onChange(null);
  }, [input]);

  const inputLabel = useMemo(() => {
    return (
      (input.value?.inputData &&
        Object.entries(input.value?.inputData)
          ?.map((entrie: [string, any]) => entrie[1]?.name)
          ?.join("\n")) ??
      label
    );
  }, [input.value?.inputData, label]);

  const handleOpenPreview = (e: any) => {
    if (e.target.tagName !== "INPUT" && (document?.dataLink || isDeleteBan)) {
      if (!["path", "svg"].includes(e.target.tagName)) {
        e.preventDefault();
        setOpen(true);
      }
    }
  };

  return (
    <>
      <Field name={namePrefix} validate={validate} key={key}>
        {({ input, meta }: any) => (
          <FileBlock
            isMaximumInputs={isMaximumInputs}
            isDeleteBan={isDeleteBan}
            required={required}
            handleCloseModal={() => setOpen(false)}
            currentFileType={currentFileType}
            handleOpenModal={handleOpenPreview}
            document={document ?? {}}
            width={width}
            height={height}
            label={inputLabel}
            subtitle={inputLabel === label ? subtitle : undefined}
            icon={icon}
            namePrefix={namePrefix}
            InputComponent={FFInputFile}
            input={input}
            meta={meta}
            multiple={multiple}
            handleDelete={handleDelete}
            handleAddInput={handleAddInput}
            handleDeleteInput={handleDeleteInput}
          />
        )}
      </Field>
      <FilePreviewDialog
        previewImg={previewImg}
        currentFileType={currentFileType}
        InputComponent={FFInputFile}
        namePrefix={namePrefix}
        input={input}
        dataLink={dataLink}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
