import { useCallback, useEffect, useState } from "react";

const getNumberArray = (n: number) => {
  return Array.from(Array(n <= 0 ? 1 : n), (_, i) => i + 1);
};

export const useMultiInputs = (length: number, maximumUnputs: number = 2) => {
  const [filesCount, setFilesCount] = useState([1]);
  const [isMaximumInputs, setIsMaximumInputs] = useState(false);

  useEffect(() => {
    setFilesCount(getNumberArray(length));
    setIsMaximumInputs(length === maximumUnputs);
  }, [length, maximumUnputs]);

  const handleAddInput = useCallback(
    (e: any) => {
      e.preventDefault();
      setFilesCount((prev) => {
        const newCount = [...prev, prev[prev.length - 1] + 1];
        setIsMaximumInputs(newCount.length === maximumUnputs);
        return newCount;
      });
    },
    [maximumUnputs]
  );

  const handleDeleteInput = useCallback(
    (e: Event, key: number, options?: { cb?: () => void; noKey?: boolean }) => {
      e.preventDefault();
      setFilesCount(
        options?.noKey
          ? getNumberArray(filesCount.length - 1)
          : filesCount.filter((count) => count !== key)
      );
      setIsMaximumInputs(false);
      options?.cb?.();
    },
    [filesCount]
  );

  return { handleAddInput, handleDeleteInput, filesCount, isMaximumInputs };
};
