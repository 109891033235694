import { CardId } from "../../../../assets/icons";
import { FC } from "react";
import { TVerificationAppFieldConfigFilePicker } from "../../models";
import { FileInput } from "../../../ui/components/FileInput";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import { fixFilesMap } from "../../network";

export const CustomFilePicker: FC<TVerificationAppFieldConfigFilePicker> = ({
  name,
  label,
  subtitle,
  docType,
  multiply,
  required,
}) => {
  const { files, uploadedFiles } = useOnBoardingFormContext();

  return (
    <FileInput
      isDeleteBan={uploadedFiles[fixFilesMap[name]]}
      document={files[docType]?.[name]}
      namePrefix={`files.${fixFilesMap[name]}`}
      label={label}
      required={required}
      subtitle={subtitle}
      icon={CardId}
      height={121}
    />
  );
};
