import { TextField } from "mui-rff";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import { VerificationAppDocumentType } from "../../models";
import { required } from "../../../../utils/validation";
import { Box, Stack } from "@mui/material";
import { CountrySelect } from "./CountrySelect";
import { DatePickerCustom } from "./DatePickerCustom";
import { CustomFilePicker } from "./CustomFilePicker";
import { docTypesComponents } from "./docTypes";
import { SelectWithData } from "./SelectWIthData";
import { useMemo } from "react";

export const DocumentForm = (props: {
  documentType: VerificationAppDocumentType;
  stepIndex?: string;
}) => {
  const { documentType, stepIndex } = props;
  const { formConfig } = useOnBoardingFormContext();
  const config = useMemo(
    () => formConfig?.elements?.[Number(stepIndex)],
    [formConfig, stepIndex]
  );
  const isNotDefaultConfig = useMemo(
    () => Boolean(config?.config?.fields),
    [config]
  );

  if (isNotDefaultConfig) {
    return (
      <Stack spacing={2} width='100%'>
        {config?.config?.fields!.map((element) => {
          if (element.type === "countrySelect") {
            return (
              <CountrySelect
                name={element.name}
                label={element.label}
                required={element.required}
              />
            );
          }
          if (element.type === "select") {
            return (
              <SelectWithData
                subtitle={element.subtitle}
                name={`data.${element.name}`}
                label={element.label}
                data={element.data}
                required={element.required}
              />
            );
          }
          if (element.type === "date") {
            return (
              <DatePickerCustom
                label={element.label}
                name={element.name}
                required={element.required}
              />
            );
          }
          if (element.type === "filePicker") {
            return (
              <CustomFilePicker
                docType={documentType}
                subtitle={element.subtitle}
                multiply={element.multiply}
                label={element.label}
                name={element.name}
                required={element.required}
              />
            );
          }

          return (
            <TextField
              name={`data.${element.name}`}
              label={element.label}
              required={element.required}
              fieldProps={{ validate: element.required ? required : undefined }}
            />
          );
        })}
        {documentType === "UserInformation" ? (
          <Box sx={{ display: "none" }}>
            <TextField name='data.date_of_birth' />
            <TextField name='data.phone_number' />
            <TextField name='data.email' />
          </Box>
        ) : null}
      </Stack>
    );
  } else {
    if (documentType in docTypesComponents) {
      const Component = docTypesComponents[documentType];
      return <Component excludedFields={config?.excludedFields} />;
    }

    return null;
  }
};
